<template>
    <div style="height:100%;overflow:hidden;">
        <div class="page-header">查看运营日志</div>
        <div class="block tab flex">
            <div class="tab_menu flex-1">
                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                    <span>{{item.name}}</span>
                </div>
            </div>
        </div>
        <div style="height: calc(100% - 180px)">
            <OperateLog v-if="tabIndex==0"></OperateLog>
            <SmsLog v-if="tabIndex==1"></SmsLog>
            <MailLog v-if="tabIndex==2"></MailLog>
        </div>
        <!-- <div class="block" style="height: calc(100% - 180px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="privs" label="日期" min-width="150">
                        <template slot-scope="scope">
                            <div>{{scope.row.created_at | moment("YYYY/MM/DD")}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_by_name" label="跟进人" min-width="105">
                        <template slot-scope="scope">
                            <div v-if="scope.row.created_by_name">{{scope.row.created_by_name}}</div>
                            <div v-else>无分配</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="privs" label="标签" min-width="100">
                        <template slot-scope="scope">
                            <div v-if="scope.row.tag1 && scope.row.tag1.length>0">
                                <span style="margin-right:5px;" v-for="(item1,idx1) in scope.row.tag1" :key="idx1">{{item1.name}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.tag2 && scope.row.tag2.length>0">
                                <span style="margin-right:5px;" v-for="(item1,idx1) in scope.row.tag2" :key="idx1">{{item1.name}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="产品问题">
                        <template slot-scope="scope">
                            <div v-if="scope.row.tag3 && scope.row.tag3.length>0">
                                <span style="margin-right:5px;" v-for="(item1,idx1) in scope.row.tag3" :key="idx1">{{item1.name}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="log_content" label="日志" min-width="150">
                        <template slot-scope="scope">
                            <div>{{scope.row.log_content}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page" :total="total"></el-pagination>
            </div>
        </div> -->
        
    </div>
</template>
<script>
    import _ from 'lodash';
    import { listLog } from '@/service/crm';
    import OperateLog from './OperateLog.vue';
    import SmsLog from './SmsLog.vue';
    import MailLog from './MailLog.vue';
    export default {
        components: {
            OperateLog,SmsLog,MailLog
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                tabList:[
                    {name:"历史运营日志"},
                    {name:"短信营销日志"},
                    {name:"邮件营销日志"},
                ],
                tabIndex:0,
                queryOpt:{
                    comp_id:1,
                    page:1,
                },
                total:0,
                list:[],
            }
        },
        watch: {
            queryOpt:{
                handler(){
                    this.debounceRefreshItems();
                },
                deep: true
            }
        },
        created() {
            this.queryOpt.comp_id = this.$route.query.id;
            this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
            this.refreshItems();
        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link)
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            refreshItems() {
                console.log('refreshItems');
                listLog(this.queryOpt).then(rst => {
                    this.total = rst.total;
                    this.list = rst.list;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            addJournal() {
                this.popTitle = "新增日志";
                this.popVisible = true;
                this.ruleForm = {
                    privs:"",
                };
            },
            FpPerson() {
                this.popTitle = "分配人员跟进";
                this.popVisible = true;
                this.ruleForm = {
                    privs:"",
                };
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    div>>> .el-dialog {
        width: 524px;
    }
    /*----选项卡----*/
    .tab {
        padding:0 20px;
    }
    .tab_menu {
        /* background: #fff;
        padding: 0 20px;
        margin: 20px; */
    }
    .tab_menu .menu_item{
        display: inline-block;
        vertical-align: top;
        width: 90px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        margin-right:50px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
</style>